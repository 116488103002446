<template>
  <div id="item-card" :class="{ 'is-selected': isSelected }" v-on="$listeners">
    <!-- row-1 -->

    <div class="row-1">
      <!-- avatar -->

      <slot name="avatar"></slot>

      <!-- ... -->

      <!-- info -->

      <div class="info">
        <slot name="info">
          <div class="title">
            <slot name="title"></slot>
          </div>

          <div class="description">
            <slot name="description"></slot>
          </div>
        </slot>
      </div>

      <!-- ... -->

      <!-- action -->

      <slot name="action"></slot>

      <!-- ... -->
    </div>

    <!-- ... -->

    <!-- row 2 -->

    <slot name="default"></slot>

    <!-- ... -->
  </div>
</template>

<script>
export default {
  name: "ItemCard",

  props: {
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
#item-card {
  background-color: var(--component-bg-color);
  border-radius: 4px;
  transition: border 0.25s ease;
  border: 1px solid transparent;
  background-clip: padding-box;

  &.is-selected {
    border: 1px solid var(--secondary);
  }

  .row-1 {
    display: flex;
    align-items: center;
    margin: 0px 16px;
    padding: 16px 0px;

    .info {
      flex: 1;
      margin: 0px 16px;

      .title {
        @extend .title-2;
        text-transform: capitalize;
        margin-bottom: 2px;
      }

      .description {
        @extend .text-sm;
        color: var(--icon-color-inverted);
      }
    }
  }
}
</style>
