export default {
  data() {
    return {
      settings: [
        {
          id: this.$nano.id(),
          icon: "eva-people",
          name: "Users & Groups",
          isExpanded: false,
          description: "Manage all your users and groups",
          children: [
            {
              id: this.$nano.id(),
              icon: "eva-person-outline",
              name: "Manage Users",
              value: "Manage Users",
              route: "users-browse",
              access: false,
              accessLabel: "Manage Users & Groups",
            },
            // {
            //   id: this.$nano.id(),
            //   icon: "eva-person-outline",
            //   name: "Browse Users",
            //   value: "browseUsers",
            //   route: "users-browse",
            // },
            // {
            //   id: this.$nano.id(),
            //   icon: "eva-plus",
            //   name: "Add a User",
            //   value: "addUsers",
            //   route: "user-builder",
            // },
            {
              id: this.$nano.id(),
              icon: "eva-email-outline",
              name: "Invite a User",
              value: "Invite Users",
              route: "user-builder",
              access: false,
              accessLabel: "Invite Users",
            },
            {
              id: this.$nano.id(),
              icon: "eva-people-outline",
              name: "Manage Groups",
              value: "Manage Groups",
              route: "groups-browse",
              access: false,
              accessLabel: "Manage Users & Groups",
            },
            // {
            //   id: this.$nano.id(),
            //   icon: "eva-people-outline",
            //   name: "Browse Groups",
            //   value: "browseGroups",
            //   route: "groups-browse",
            // },
            // {
            //   id: this.$nano.id(),
            //   icon: "eva-plus",
            //   name: "Add a Group",
            //   value: "addGroup",
            //   route: "group-builder",
            // },
          ],
        },
        {
          id: this.$nano.id(),
          icon: "mdi-account-cog",
          name: "Profile",
          isExpanded: false,
          description: "Manage all your menu settings",
          children: [
            {
              id: this.$nano.id(),
              icon: "mdi-account-cog-outline",
              name: "Manage Profiles",
              value: "Manage Profiles",
              route: "profile-browse",
              access: false,
              accessLabel: "Manage Profiles",
            },
            // {
            //   id: this.$nano.id(),
            //   icon: "eva-person-outline",
            //   name: "Browse Profile",
            //   value: "browseProfile",
            //   route: "profile-browse",
            // },
            // {
            //   id: this.$nano.id(),
            //   icon: "eva-plus",
            //   name: "Add a Profile",
            //   value: "addProfile",
            //   route: "profile-builder",
            // },
            // {
            //   id: this.$nano.id(),
            //   icon: "eva-email-outline",
            //   name: "Invite a Profile",
            //   value: "inviteProfile",
            //   route: "profile-builder",
            // },
          ],
        },
        {
          id: this.$nano.id(),
          icon: "mdi-file-document",
          name: "Reassign Request",
          isExpanded: true,
          description: "Manage all your reassign settings",
          children: [
            {
              id: this.$nano.id(),
              icon: "mdi-file-document-outline",
              name: "Manage Reassign",
              value: "Manage Leaves",
              route: "leave-browse",
              access: true,
              accessLabel: "Manage Leaves",
            },
            // {
            //   id: this.$nano.id(),
            //   icon: "eva-person-outline",
            //   name: "Browse Profile",
            //   value: "browseProfile",
            //   route: "profile-browse",
            // },
            // {
            //   id: this.$nano.id(),
            //   icon: "eva-plus",
            //   name: "Add a Profile",
            //   value: "addProfile",
            //   route: "profile-builder",
            // },
            // {
            //   id: this.$nano.id(),
            //   icon: "eva-email-outline",
            //   name: "Invite a Profile",
            //   value: "inviteProfile",
            //   route: "profile-builder",
            // },
          ],
        },
        {
          id: this.$nano.id(),
          icon: "mdi-view-grid-plus",
          name: "Connection",
          isExpanded: false,
          description: "Connect ezofis with your favorite apps",
          children: [
            {
              id: this.$nano.id(),
              icon: "eva-settings-outline",
              name: "Manage Connections",
              value: "manage Connections",
              route: "integration-browse",
              access: false,
              accessLabel: "Manage Connections",
            },
          ],
        },
        // {
        //   id: this.$nano.id(),
        //   icon: "mdi-text-box",
        //   name: "Forms",
        //   isExpanded: false,
        //   description: "Find all the form entries",
        //   children: [
        //     {
        //       id: this.$nano.id(),
        //       icon: "mdi-table",
        //       name: "Master Tables",
        //       route: "forms",
        //     },
        //     {
        //       id: this.$nano.id(),
        //       icon: "mdi-table",
        //       name: "External Form Submissions",
        //       route: "",
        //     },
        //   ],
        // },
        {
          id: this.$nano.id(),
          icon: "eva-shopping-cart",
          name: "License",
          isExpanded: false,
          description: "Find a list of all the pricing plans",
          children: [
            {
              id: this.$nano.id(),
              icon: "eva-person-outline",
              name: "Manage Licenses",
              value: "Manage Licenses",
              route: "license",
              access: false,
              accessLabel: "Manage Licenses",
            },
            // {
            //   id: this.$nano.id(),
            //   icon: "eva-person-outline",
            //   name: "Browse Licenses",
            //   value: "browseLicenses",
            //   route: "license",
            // },
            // {
            //   id: this.$nano.id(),
            //   icon: "eva-plus",
            //   name: "Add a License",
            //   value: "addLicense",
            //   route: "",
            // },
          ],
        },
        // {
        //   id: this.$nano.id(),
        //   icon: "eva-bell",
        //   name: "Notifications",
        //   isExpanded: false,
        //   description: "Configure how you want to be notified",
        //   children: [],
        // },
      ],
    };
  },

  computed: {
    _settings() {
      if (this.$store.state.profileMenus.length) {
        this.settings.forEach((row) => {
          row.children.forEach((tab) => {
            if (!tab.access) {
              tab.access = this.$store.state.profileMenus.find(
                (menu) => menu.settings
              )?.Menu[tab.accessLabel];
            }
          });
        });
      }
      return this.settings.filter((row) =>
        row.children.find((tab) => tab.access)
      );
    },
  },
};
