<template>
  <div id="settings-card">
    <ItemCard class="cursor-pointer" @click="toggleChildren">
      <template #avatar>
        <BaseIcon :name="settings.icon" inherit-color class="icon" />
      </template>

      <template #title>{{ settings.name }}</template>

      <template #description>
        {{ settings.description }}
      </template>

      <template #action>
        <BaseActionButton
          :icon="settings.isExpanded ? 'eva-chevron-up' : 'eva-chevron-down'"
          :color="settings.isExpanded ? 'secondary' : 'gray'"
          is-flat
        />
      </template>

      <template #default>
        <div
          class="children"
          :class="{ 'is-expanded': settings.isExpanded }"
          @click.stop
        >
          <BaseSeparator has-inset />

          <template v-for="child in settings.children">
            <ListItem
              v-if="child.access"
              :key="child.id"
              is-sparse
              :icon="child.icon"
              :label="child.name"
              @click="goto(child.route)"
            />
          </template>
        </div>
      </template>
    </ItemCard>
  </div>
</template>

<script>
import ItemCard from "@/components/common/display/ItemCard.vue";
import ListItem from "@/components/common/ListItem.vue";

export default {
  name: "SettingsCard",

  components: { ItemCard, ListItem },

  props: {
    settings: {
      type: Object,
      required: true,
    },
  },

  methods: {
    toggleChildren() {
      this.$emit("toggle");
    },

    goto(route) {
      this.$router.push({ name: route });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./src/styles/core/typography.scss";

#settings-card {
  .icon {
    color: var(--secondary);
  }

  .action {
    margin-right: -8px;
  }

  .children {
    height: 0px;
    overflow: hidden;
    transition: all 0.25s ease;

    &.is-expanded {
      padding: 4px 0px;
      height: 300px;
    }
  }
}
</style>
