<template>
  <AppLayout>
    <ViewWrapper
      title="settings"
      hide-action-bar
      :breadcrumbs="breadcrumbs"
      @quickHelp="showQuickHelp"
    >
      <!-- settings -->

      <div class="q-mt-lg">
        <div class="row q-col-gutter-md">
          <div
            v-for="setting in _settings"
            :key="setting.id"
            class="col-md-4 col-lg-3"
          >
            <SettingsCard
              :settings="setting"
              @toggle="setting.isExpanded = !setting.isExpanded"
            />
          </div>
        </div>
      </div>

      <!-- ... -->

      <!-- quick help -->

      <QuickHelp v-model="quickHelp" />

      <!-- ... -->
    </ViewWrapper>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/app/AppLayout.vue";
import ViewWrapper from "@/components/common/view-wrapper/ViewWrapper.vue";
import QuickHelp from "@/components/common/QuickHelp.vue";

import SettingsCard from "./components/SettingsCard.vue";

import settings from "./mixins/settings.js";

export default {
  name: "Settings",

  components: { AppLayout, ViewWrapper, SettingsCard, QuickHelp },

  mixins: [settings],

  data() {
    return {
      breadcrumbs: [
        {
          id: this.$nano.id(),
          label: "dashboard",
          route: "dashboard",
        },
        {
          id: this.$nano.id(),
          label: "settings",
          route: "",
        },
      ],
      quickHelp: false,
    };
  },

  created() {
    const deviceWidth = this.$q.screen.width;
    let openCardCount = deviceWidth > 1366 ? 4 : 3;

    for (let i = 0; i < openCardCount; i++) {
      this.settings[i].isExpanded = true;
    }
  },

  methods: {
    showQuickHelp() {
      this.quickHelp = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
